<template>
    <div class="login-form">
        <login-logo></login-logo>
        <el-form ref="ruleFormRef"
            :model="form"
            :rules="rules"
            class="demo-ruleForm">
            <el-form-item prop="username">
                <el-input v-model="form.username"
                    placeholder="用户名" />
            </el-form-item>

            <el-form-item prop="password">
                <el-input show-password
                    v-model="form.password"
                    placeholder="登录密码" />
            </el-form-item>

            <el-form-item prop="captcha"
                class="captcha">
                <el-input v-model="form.code"
                    placeholder="验证码"
                    maxlength="4">
                    <template #suffix>
                        <!-- <img @click="handleImgClick"
                            :src="captcha.image"
                            alt=""> -->
                    </template>
                </el-input>
            </el-form-item>
            <div class="operate">
                <span>密码忘记了</span>
                <span @click="handleForgotPasswordClick">重置密码</span>
            </div>
            <el-button v-if="isLogin"
                class="login-btn"
                @click="handleLoginClick"
                type="primary">登录</el-button>
        </el-form>
    </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch } from 'vue'
import { rules } from '../config/form-rules.js'
import { START_LOCATION, useRouter } from 'vue-router'
import LoginLogo from './login-logo'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
import cache from '@/utils/cache.js'
import { getCode } from '@/service/login'

export default defineComponent({
  props: {
    isLogin: {
      type: Boolean,
      default: true
    }
  },
  components: {
    LoginLogo
  },
  setup() {
    const store = useStore()
    const router = useRouter();
    const ruleFormRef = ref(null)
    const form = reactive({
      username: '',
      password: '',
      code: '',
      token: ''
    })
    const captcha = computed(() => store.state.login.captcha)

    const initPage = () => {
      store.dispatch('login/getYanzhengCodeAction')

    }
    initPage()

    watch(captcha, (n) => {
      if (n != null) {
        form.token = n.token
        // cache.setCache('token', n.token)
      }
    }, {
      deep: true
    })
    const handleImgClick = () => {
      initPage()
    }

    const handleLoginClick = () => {
      ruleFormRef.value.validate((valid) => {
        if (valid) {
          store.dispatch('login/accountLoginAction', { ...form })
        } else {
          if (form.username.length == 0 || form.password.length || form.captcha.length == 0) {
            ElMessage({
              message: '用户名或密码或验证码不得为空!',
              type: 'warning',
            })

          }

          return false
        }

      })
    }

    const handleForgotPasswordClick = () => {
      router.push('/forgotPassword')
    }

    return {
      form,
      handleLoginClick,
      rules,
      ruleFormRef,
      handleForgotPasswordClick,
      captcha,
      handleImgClick
    }
  }
})
</script>

<style scoped lang="less">
.login-form {
  padding: 36px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .captcha {
    overflow: hidden;

    img {
      width: 90px;
      cursor: pointer;
    }
  }

  .login-btn {
    width: 100%;
    margin-top: 16px;
    height: 48px;
  }

  .operate {
    text-align: right;
    font-size: 13px;
    cursor: pointer;

    span:nth-child(1) {
      color: rgba(80, 80, 80, 1);
      margin-right: 4px;
    }

    span:nth-child(2) {
      color: rgba(42, 130, 228, 1);
    }
  }
}
</style>




